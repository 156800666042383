import { useForm as ReactHookForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalizedSchemas, Schemas } from '../../config/localized-schemas';
import { useTranslation } from '~/shared/utils';
import { useCountryOptions, useValidationRules } from '~/shared/utils/form';

type Props = UseFormProps & {
    /**
     * Supply the wanted schema as a string
     * Schemas can be updated and added in localized-schemas.ts
     */
    validationSchema?: Schemas;
};

/**
 * Use this hook to create a form with validation.
 * Use in coherence with Yup validation schema.
 * and Yup resolver.´
 *
 * Pass Yup validation schema as props.validationSchema
 */
export const useForm = (props?: Props): UseFormReturn => {
    const { translate } = useTranslation();
    const { currentDeliveryKey } = useCountryOptions();
    const validationRules = useValidationRules(currentDeliveryKey);

    const schemas = getLocalizedSchemas(translate, validationRules);
    const existingSchema = props?.validationSchema && schemas[props.validationSchema];

    const resolver = existingSchema ? { resolver: yupResolver(existingSchema) } : {};

    return ReactHookForm({ ...resolver, ...props });
};
