import React, { forwardRef, useRef, useState, memo, MouseEvent, TouchEvent } from 'react';
import { Button, InputField, InputFieldProps } from '~/shared/components';
import { StyledContainer } from './styled';
import Eye from '$icons/solidAsh/eye.svg';
import EyeOff from '$icons/solidAsh/eye-off.svg';

type RefType = HTMLInputElement | null;

/**
 * Inputfield HOC for adding password visibility toggle
 */
export const PasswordToggle = memo(
    forwardRef<RefType, InputFieldProps>((props, ref) => {
        const localRef = useRef<RefType>(null);
        const [visible, setVisible] = useState<boolean>(false);

        const togglePasswordVisibility = (event: React.MouseEvent) => {
            event?.preventDefault();
            setVisible(!visible);

            setTimeout(() => {
                if (localRef.current) {
                    localRef.current.setSelectionRange(-1, -1);
                }
            });
        };

        // Creates a local ref and invokes the forwarded ref
        const storeRefs = (innerRef: RefType) => {
            if (localRef && typeof ref === 'function') {
                localRef.current = innerRef;
                ref(innerRef);
            }
        };

        return (
            <StyledContainer>
                <InputField
                    ref={storeRefs}
                    trailingIcon={
                        <Button
                            children={visible ? <EyeOff /> : <Eye />}
                            onClick={togglePasswordVisibility}
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                            onTouchStart={(e: TouchEvent<HTMLButtonElement>) => e.preventDefault()}
                            variant="tertiary"
                            showHoverIndicator={false}
                            type="button"
                        />
                    }
                    {...props}
                    type={visible ? 'text' : 'password'}
                />
            </StyledContainer>
        );
    })
);
