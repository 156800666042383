import { useFranchise } from '~/shared/utils';
import { useMemo } from 'react';

const INTERNATIONAL_KEY = 'INT';

/**
 * Use this hook to get the delivery options for a country.
 * @param props
 * @example
 * const { options } = useDeliveryOptions({ countryCode: 'US' });
 * console.log(options);
 */
export const useCountryOptions = () => {
    const { activeMarket, franchiseMarket } = useFranchise();
    const deliveryOptions = useMemo(
        () =>
            franchiseMarket.markets
                ?.filter(({ countryKey }) => countryKey !== INTERNATIONAL_KEY)
                .map(({ countryName: title = '', countryKey: value = '' }) => ({
                    title,
                    value,
                })),
        [franchiseMarket]
    );

    const countryKey = activeMarket?.countryKey;
    const countryName = activeMarket?.countryName;

    // Don't ever use INT as a country key.
    const currentDeliveryKey = countryKey === INTERNATIONAL_KEY ? '' : countryKey;
    const currentCountryName = countryKey === INTERNATIONAL_KEY ? '' : countryName;

    return { deliveryOptions: deliveryOptions || [], currentDeliveryKey, currentCountryName };
};
