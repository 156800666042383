import { bool, boolean, object, setLocale, string, number } from 'yup';
import { CountryRules, TranslationKey } from '~/lib/data-contract';
import { LocaleObject } from 'yup/lib/locale';
import { SIGNUP_SOURCE } from '~/features/basket/constants/signup-source';

type Translator = (key: TranslationKey, interpolation?: Record<string, string | number>) => string;

let localeConfig: LocaleObject | null = null;

export type Schemas = keyof ReturnType<typeof getLocalizedSchemas>;

/**
 *
 * @important All yup schemas that is localized (Read error messages in different languages)
 * must be in this file. If a yup import happens before yup's setLocale function call
 * then the schema will NOT be localized
 *
 * @param translator
 * @param validationRules
 */
export const getLocalizedSchemas = (translator: Translator, validationRules: CountryRules) => {
    if (!localeConfig) {
        localeConfig = getLocaleConfig(translator);
        setLocale(localeConfig);
    }

    const passwordComplexity = string().matches(
        new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/),
        translator('form.validation.passwordComplexity')
    );

    const phoneValidation = string()
        .min(validationRules.minimumPhoneLength || 0)
        .max(validationRules.maximumPhoneLength || 255)
        .matches(new RegExp(validationRules.phoneRegex || ''));

    return {
        signUp: object({
            email: string().email().required(),
            password: passwordComplexity.required(),
            newsletter: boolean().oneOf([true], translator('form.validation.newsletter')),
        }).required(),
        signIn: object({
            credential: string()
                .required()
                .test('', translator('form.validation.emailOrPhone'), (value) => {
                    return (
                        string().email().isValidSync(value) || phoneValidation.isValidSync(value)
                    );
                }),
            password: string().required(),
        }).required(),
        activateAccount: object({
            firstname: string().required(),
            lastname: string().nullable(),
            signupSource: number(),
            password: string().when('signupSource', {
                is: (source: SIGNUP_SOURCE) =>
                    source !== SIGNUP_SOURCE.CHECKOUT && source !== SIGNUP_SOURCE.CLUB,
                then: passwordComplexity.required(),
                otherwise: string().nullable(),
            }),
            birthday: string().nullable(),
            notifyBySms: boolean(),
            phone: string().when('notifyBySms', {
                is: (notifyBySms: boolean) => !!notifyBySms,
                then: phoneValidation,
                otherwise: string().nullable(),
            }),
        }),
        editAccount: object({
            email: string().email(),
            firstname: string().required(),
            lastname: string().nullable(),
            phone: phoneValidation.nullable(),
            gender: string().nullable(),
            birthday: string().required(),
            address: object({
                street: string().when({
                    is: (value: string) => !!value,
                    then: string().matches(
                        new RegExp(validationRules.addressRegex || ''),
                        translator('form.validation.addressRegex')
                    ),
                    otherwise: string().nullable(),
                }),
                zipCode: string().when({
                    is: (value: string) => !!value,
                    then: string().matches(new RegExp(validationRules.zipcodeRegex || '')),
                    otherwise: string().nullable(),
                }),
                city: string().nullable(),
            }),
        }).required(),
        editPassword: object({
            currentPassword: string().required(),
            newPassword: passwordComplexity.required(),
        }).required(),
        resetPassword: object({
            email: string().email().required(),
        }).required(),
        invoiceInformation: object({
            mail: string().email().required(),
            phonePrefix: string().required(),
            createUser: boolean(),
            phone: phoneValidation.required(),
            invoice: object().shape(
                {
                    firstName: string().required(),
                    lastName: string().required(),
                    streetName: string()
                        .matches(
                            new RegExp(validationRules.addressRegex || ''),
                            translator('form.validation.addressRegex')
                        )
                        .required(),
                    city: string().required(),
                    postalCode: string()
                        .matches(new RegExp(validationRules.zipcodeRegex || ''))
                        .required(),
                    countryCode: string().when('countryCode', {
                        is: (countryCode: boolean) => countryCode !== undefined,
                        then: string().required(),
                    }),
                    comment: string().max(500),
                },
                [['countryCode', 'countryCode']]
            ),
            password: string().when('createUser', {
                is: (createUser: boolean) => !!createUser,
                then: passwordComplexity.required(),
            }),
        }).required(),
        privateAddress: object({
            shippingMethodId: string().required(),
            shipToInvoiceAddress: boolean().required(),
            shipping: object().when('shipToInvoiceAddress', {
                is: (shipToInvoiceAddress: boolean) => !shipToInvoiceAddress,
                then: object().shape({
                    firstName: string().required(),
                    lastName: string().required(),
                    streetName: string()
                        .matches(
                            new RegExp(validationRules.addressRegex || ''),
                            translator('form.validation.addressRegex')
                        )
                        .required(),
                    city: string().required(),
                    postalCode: string()
                        .matches(new RegExp(validationRules.zipcodeRegex || ''))
                        .required(),
                }),
                otherwise: object().shape({}),
            }),
        }).required(),
        businessAddress: object({
            shippingMethodId: string().required(),
            shipping: object({
                firstName: string().required(),
                lastName: string().required(),
                company: string().required(),
                streetName: string()
                    .matches(
                        new RegExp(validationRules.addressRegex || ''),
                        translator('form.validation.addressRegex')
                    )
                    .required(),
                city: string().required(),
                postalCode: string()
                    .matches(new RegExp(validationRules.zipcodeRegex || ''))
                    .required(),
            }),
        }).required(),
        agent: object({
            shippingMethodId: string().required(),
            agentId: string().required(),
        }),
        empty: object({
            shippingMethodId: string().required(),
        }),
        payment: object({
            consent: bool().oneOf([true]),
            eRPPaymentId: string().required(),
        }),
        recoverPassword: object({
            newPassword: passwordComplexity.required(),
        }).required(),
        reserve: object({
            name: string().required(),
            phone: phoneValidation.required(),
            email: string().email().required(),
            variantId: string().required(),
            storeId: number().required(),
            quantity: number().required(),
        }),
    };
};

/**
 * Returns the locale config for the current locale.
 * Add all localized validation messages here.
 * Fallback to english if no translation is available.
 *
 * Supply translation key from validation error messages.
 */

const getLocaleConfig = (translator: Translator): LocaleObject => {
    return {
        mixed: {
            default: () => translator('form.validation.default'),
            required: () => translator('form.validation.required'),
        },
        string: {
            email: () => translator('form.validation.email'),
            min: ({ min }) => translator('form.validation.min', { min }),
            max: ({ max }) => translator('form.validation.max', { max }),
            matches: ({ originalValue }) =>
                translator('form.validation.matches', { originalValue }),
        },
        number: {
            min: ({ min }) => translator('form.validation.min', { min }),
            max: ({ max }) => translator('form.validation.max', { max }),
            integer: () => translator('form.validation.integer'),
        },
    };
};
