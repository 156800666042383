import { useFrame } from '~/shared/utils';
import { CountryRules } from '~/lib/data-contract';
import { useMemo } from 'react';
import { useBasket } from '~/features/basket';

type Props = string | undefined;

/**
 * Use this hook to get the validation rules for a country.
 * @param props
 * @returns {CountryRules}
 * @example
 * const { rules } = useValidationRules({ countryCode: 'US' });
 * const { rules: rules2 } = useValidationRules({ countryCode: 'CA' });
 * console.log(rules.zipCode.maxLength); // 5
 * console.log(rules2.zipCode.maxLength); // 7
 */
export const useValidationRules = (countryCodeProp?: Props): CountryRules => {
    const { data: frame } = useFrame();
    const { data: basket } = useBasket();

    const countryCodeToHit =
        countryCodeProp || basket?.deliveryInformationViewModel?.invoiceAddress?.countryCode;

    const validationRules = useMemo(
        () =>
            frame?.settings?.validationRules?.find(
                ({ countryCode }) => countryCode === countryCodeToHit
            ) || {},
        [frame, countryCodeToHit]
    );

    return validationRules;
};
